import React from "react";
import { Link } from "react-router-dom";

import logo from "./assets/SP.svg";

import { NavigationStyle, MenuStyle, Transition } from "./style";

export default class Navigation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      show: true,
      scrollPos: 0,
      open: false,
    };
    this.handleScroll = this.handleScroll.bind(this);
    this.onMenuOpen = this.onMenuOpen.bind(this);
    this.setOpen = this.setOpen.bind(this);
  }

  handleScroll() {
    const { scrollPos } = this.state;
    this.setState({
      ...this.state,
      scrollPos: document.body.getBoundingClientRect().top,
      show: document.body.getBoundingClientRect().top > scrollPos,
    });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  onMenuOpen(e) {
    e.preventDefault();
    this.setState({ ...this.state, open: !this.state.open });
    if (this.state.open) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }

  setOpen(value) {
    this.setState({
      ...this.state,
      open: value,
    });
  }

  render() {
    return (
      <Transition>
        <NavigationStyle
          darkmode={this.props.darkMode}
          className={this.state.show ? "active" : "hidden"}
        >
          <Link className="logo" to="/">
            <img src={logo} alt="my-logo" />
          </Link>
          <div
            id="nav-icon"
            open={this.state.open}
            onClick={this.onMenuOpen}
            className={this.state.open ? "open" : ""}
          >
            <span></span>
            <span></span>
            <span></span>
          </div>
          <Menu
            darkMode={this.props.darkMode}
            toggleMode={this.props.toggleMode}
            open={this.state.open}
            setOpen={this.setOpen}
          />
        </NavigationStyle>
      </Transition>
    );
  }
}

function Menu({ open, setOpen, darkMode, toggleMode }) {
  function onLinkClick() {
    const mediaQuery = window.matchMedia("(min-width: 900px)");

    setOpen(!open);
    if (mediaQuery.matches) {
      document.body.style.overflow = "auto";
    } else if (open) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  }
  return (
    <MenuStyle darkmode={darkMode} open={open}>
      <ul>
        <li onClick={onLinkClick}>
          <Link to="/about">About</Link>
        </li>
        <li onClick={onLinkClick}>
          <a href="/resume.pdf">Resume</a>
        </li>
      </ul>

      <div className="dark-mode__toggle">
        <div
          onClick={toggleMode}
          className={darkMode ? "toggle toggled" : "toggle"}
        />
      </div>
    </MenuStyle>
  );
}
