import React from "react";
import { TiLocationArrow } from "react-icons/ti";

import mainImage from "./assets/sandrava.JPG";

import { AboutStyle } from "./style";

const About = () => {
  return (
    <AboutStyle id="about">
      <div className="section-header">
        <h2>Who is Sandrava?</h2>
        <span></span>
      </div>
      <div className="about-content">
        <figure>
          <img src={mainImage} alt="sandrava-about-me" />
        </figure>

        <section className="about-me">
          <p>
            I am a software engineer with interests in security and user
            experience. I believe that a business will thrive if the
            product/service is of excellent quality and its delivery is
            customer-focused. My goal is to build secure and highly performant
            applications with rich user interfaces.
          </p>
          <p>
            I graduated with a Bachelor's Degree in Electrical and Electronics
            Engineering from the Federal University of Technology, Akure in
            Nigeria. While in school up until a year after graduation, I worked
            as a media personality, hosting shows at in-person events and on
            radio and television.
          </p>
          <p>
            Since moving to tech, I have worked both in teams and alone to build
            fault-tolerant applications that cater to real users. I've also
            created technical content for companies such as{" "}
            <a href="https://www.highlight.io/" rel="noopener noreferrer" target="_blank">
              Highlight
            </a>
            ,{" "}
            <a href="https://www.avo.app/" rel="noopener noreferrer" target="_blank">
              Avo
            </a>
            ,{" "}
            <a href="https://www.striim.com/" rel="noopener noreferrer" target="_blank">
              Striim
            </a>
            , and{" "}
            <a href="https://www.honeycomb.io/" rel="noopener noreferrer" target="_blank">
              Honeycomb
            </a>
            .
          </p>
          <p>Here are some of the technologies I've worked with recently:</p>

          <section className="tech-stack">
            <section className="tech-stack__content">
              <div>
                <span className="colored">
                  <TiLocationArrow className="bullet" />
                </span>
                <span>JavaScript</span>
              </div>
              <div>
                <span className="colored">
                  <TiLocationArrow className="bullet" />
                </span>
                <span>React</span>
              </div>
              <div>
                <span className="colored">
                  <TiLocationArrow className="bullet" />
                </span>
                <span>Vue</span>
              </div>
            </section>
            <section className="tech-stack__content">
              <div>
                <span className="colored">
                  <TiLocationArrow className="bullet" />
                </span>
                <span>Python</span>
              </div>
              <div>
                <span className="colored">
                  <TiLocationArrow className="bullet" />
                </span>
                <span>Go</span>
              </div>
              <div>
                <span className="colored">
                  <TiLocationArrow className="bullet" />
                </span>
                <span>Bootstrap</span>
              </div>
            </section>
          </section>
        </section>
      </div>
    </AboutStyle>
  );
};

export default About;
