import styled from "styled-components";

export const StyledHome = styled.div`
  max-width: 80%;
  margin-top: 9rem;
  margin-left: 1rem;

  h2,
  h3,
  h4 {
    margin: 10px auto;
    font-weight: 700;
  }

  h2 {
    font-size: 50px;
  }

  h2 span {
    display: block;
    font-size: 35px;
  }

  h3 {
    font-size: 30px;
  }

  h4 {
    font-size: 25px;
  }

  p {
    font-size: 20px;
  }

  .button {
    width: 200px;
    height: 55px;
    overflow: hidden;
    font-family: "Cormorant Upright", serif;
    background: transparent;
    color: #39ff14;
    border: 2px solid #39ff14;
    font-size: 25px;
    font-weight: 700;
    cursor: pointer;
    text-align: center;
    display: flex;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    position: relative;
    justify-content: center;
    flex-direction: column;
    z-index: 1;
    text-decoration: none;
  }

  .button:hover {
    color: #ffffff;
  }

  .button:after {
    content: "";
    position: absolute;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    transition: all 0.4s;
    width: 100%;
    height: 0;
    bottom: 0;
    left: 0;
    background: #39ff14;
    z-index: -1;
  }

  .button:hover:after,
  .button:active:after {
    height: 100%;
  }

  @media screen and (min-width: 900px) {
    margin-left: 3rem;
    margin-right: 3rem;
    max-width: 600px;
  }
`;

export const AboutStyle = styled.div`
  margin: 6rem 1rem;

  h2 {
    font-size: 40px;
    margin: 10px auto;
    font-weight: 700;
  }

  figure {
    margin: 20px auto;
    height: 350px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: 100% 40%;
    }
  }

  .about-me {
    p {
      font-size: 20px;

      a {
        color: inherit;
      }
    }
  }

  .tech-stack {
    margin: 20px 0;
    width: 100%;
    max-width: 300px;
    display: flex;
    justify-content: space-between;
  }

  .bullet {
    transform-origin: 4px;
    transform: rotate(45deg);
  }

  .tech-stack__content div {
    font-size: 20px;
  }

  @media screen and (min-width: 900px) {
    margin-left: 3rem;
    margin-right: 3rem;

    .about-content {
      display: flex;
      flex-direction: row-reverse;
    }

    .about-me {
      max-width: 55%;
    }

    figure {
      width: 40%;
      height: 450px;
    }
  }
`;

export const Transition = styled.div`
  .active {
    visibility: visible;
    transition: all 200ms ease-in;
  }
  .hidden {
    visibility: hidden;
    transition: all 200ms ease-out;
    transform: translate(0, -100%);
  }
`;

export const MenuStyle = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: ${({ darkmode }) => (darkmode ? "#0A192F" : "white")};
  padding: 1rem 4rem;
  position: fixed;
  top: 0;
  right: 0;
  transition: transform 0.3s ease-in-out;
  transform: ${({ open }) => (open ? "translateX(0%)" : "translateX(100%)")};

  ul {
    list-style-type: none;
    font-size: 26px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    padding-right: 2rem;

    li {
        margin-bottom: 4rem;
    }

    li a:hover {
      color: #39ff14;
    }
  }

  .dark-mode__toggle {
    background: #39ff14;
    border-radius: 50px;
    height: 26px;
    position: relative;
    width: 45px;
  }

  .toggle {
    background: #0a192f;
    border-radius: 50px;
    height: 21.5px;
    top: 2px;
    left: 3px;
    position: absolute;
    transition: 0.3s;
    width: 21px;
    cursor: pointer;
  }

  .toggled {
    left: 22px;
  }

  a {
    font-weight: bold;
    text-decoration: none;
    color: ${({ darkmode }) => (darkmode ? "#C0C0C0" : "black")};
  }

  @media screen and (min-width: 900px) {
    display: flex;
    flex-direction: row;
    transform: none;
    background: transparent;
    height: 40px;
    width: 50%;
    max-width: 400px;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    position: relative;
    margin-right: 1rem;

    ul {
      flex-direction: row;
      padding: 0;
      height: 100%;
      width: 70%;

      li {
        font-size: 22px;
        margin-bottom: 0;
      }
    }

    .dark-mode__toggle {
      right: 1rem;
    }
  }
`;

export const NavigationStyle = styled.div`
  display: flex;
  justify-content: space-between;
  height: 50px;
  position: fixed;
  top: 0;
  left: 0;
  background-color: ${({ darkmode }) => (darkmode ? "#0A192F" : "white")};
  width: 100%;
  margin: 0 auto;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 5px;
  z-index: 100;

  .logo {
    width: 7rem;
    height: 100px;
  }

  .logo img {
    width: 100%;
    overflow: hidden;
    object-position: 0;
  }

  #nav-icon {
    position: absolute;
    top: 21px;
    right: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 2rem;
    height: 1.5rem;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    z-index: 10;

    &:focus {
      outline: none;
    }

    span {
      width: 2rem;
      background-color: #39ff14;
      height: 0.12rem;
      border-radius: 1px;
      transition: all 0.3s linear;
      position: relative;
      transform-origin: right;

      &:nth-child(2) {
        left: 0.5rem;
        width: 1.5rem;
      }

      &:nth-child(3) {
        left: 1rem;
        width: 1rem;
      }
    }
  }

  #nav-icon.open span:nth-child(1) {
    width: 1.4rem !important;
    -webkit-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    transform: rotate(-45deg) !important;
  }

  #nav-icon.open span:nth-child(2) {
    opacity: 0 !important;
    transform: translateX(-20px);
  }

  #nav-icon.open span:nth-child(3) {
    width: 1.4rem !important;
    left: 0 !important;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg) !important;
  }

  @media screen and (min-width: 900px) {
    #nav-icon {
      display: none;
    }

    .logo {
      margin-left: 1rem;
    }
  }
`;
