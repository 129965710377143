import useLocalStorage from "./useLocalStorage";
import { useEffect } from "react";

const useDarkMode = (key, initialValue) => {
    const [theme, setTheme] = useLocalStorage(key, initialValue);
    
    useEffect(() => {
        const body = document.querySelector('body');
        theme ? body.classList.add('darkMode') : body.classList.remove('darkMode') 
    }, [theme])

    return [theme, setTheme];
}

export default useDarkMode;