import React from "react";
import { FaGithub, FaLinkedin, FaTwitter } from "react-icons/fa";
import { IoMdMail } from "react-icons/io";
import "./App.css";
import Home from "./components/Home";
import About from "./components/About";
import Navigation from "./components/Navigation";
import useDarkMode from "./components/hooks/usedarkMode";
import { Route } from "react-router-dom";

function App() {
  const [darkMode, setDarkMode] = useDarkMode("dark-theme", true);

  function toggleMode(e) {
    e.preventDefault();
    setDarkMode(!darkMode);
  }

  return (
    <div className="App">
      <Navigation darkMode={darkMode} toggleMode={toggleMode} />
      
      <Route exact path="/" component={Home} />
      <Route path="/about" component={About} />

      <footer className="contact-icons">
        <a href="mailto: sandra_philips@outlook.com">
          <IoMdMail />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://www.linkedin.com/in/sandravaphilips"
        >
          <FaLinkedin />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://github.com/Sandravaphilips"
        >
          <FaGithub />
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://twitter.com/SandravaPhilips"
        >
          <FaTwitter />
        </a>
      </footer>
      
    </div>
  );
}

export default App;
